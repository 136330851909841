.featured-product {
    min-height: 300px;
}

.featured-product_picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 900px) {
    .featured-product .featured-product_inner {
        flex-direction: column;
    }

    .featured-product_header-container {
        text-align: center;
    }
}
