* {
    font-family: 'Inter', sans-serif;
    /* Variables */
    --topbarHeight: 56px;
    --layout-sidebarWidth: 280px;
    --boxShadow: 0 5px 10px 0 rgb(0 0 0 / 6%);
    --containerWidth: 85vw;
    --cartZIndex: 997;
    --webstoreBuilderHighlightColor: #a3caff;
}

img {
    object-fit: cover;
}

/* LAYOUTS */
.layout-section {
    display: grid;
    grid-template-columns: repeat(12, 8.33%);
    grid-auto-rows: max-content;
    column-gap: 0;
}

.layout-left-sidebar_grid,
.layout-right-sidebar_grid {
    display: grid;
    grid-gap: var(--rds-spacingSmall);
}

@media screen and (max-width: 900px) {
    .layout-left-sidebar_grid,
    .layout-right-sidebar_grid {
        display: flex !important;
        flex-direction: column-reverse;
    }
}
/* LAYOUTS */

.container,
.container_sm {
    min-width: 300px;
    max-width: 1200px;
    width: var(--containerWidth);
    margin-left: auto;
    margin-right: auto;
}
.container_sm {
    max-width: 950px;
}

@media screen and (max-width: 900px) {
    .container {
        width: 95% !important;
    }
}

#nav {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: calc(var(--cartZIndex) - 2);
    user-select: none;
}

#nav.scrolled {
    box-shadow: 0 5px 10px 0 #00000010;
}

.nav-container {
    position: relative;
    z-index: 2;
}

.nav_search-results {
    padding: 10px 0;
    position: absolute;
    right: 0;
    left: 0;
    transition: 0.2s all;
    transform: scaleY(0);
    transform-origin: top;
    z-index: 3;
    bottom: 0;
    top: 13%;
}
.nav_search-results.nav_search-results_active {
    transform: scaleY(1);
}
.nav_search-results::after {
    content: '';
    background: #00000090;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    z-index: -2;
    opacity: 0;
    transition: 0s opacity;
}
.nav_search-results.nav_search-results_active::after {
    opacity: 1;
    transition: 0.8s opacity;
}

@media screen and (max-width: 900px) {
    .nav-session {
        display: none;
    }
}

button * {
    font-size: 16px;
}

.product-card_standard .rds-btn svg {
    width: 16px !important;
}
article.product_details {
    padding: 20px;
    border-left: unset !important;
    border-right: unset !important;
    border-bottom: unset !important;
}

.hero {
    background-size: cover !important;
    transition: 0.2s all;
}

.hero .hero_header {
    animation: 2s hero_header_animation;
    transition: 0.2s opacity, 0.2s transform;
}
@keyframes hero_header_animation {
    from {
        opacity: 0.5;
        transform: translateY(-15%);
    }
    to {
        opacity: 1;
        transform: translate(0);
    }
}

.hero .rds-tag {
    line-height: 0.8;
}

.hero .rds-tag * {
    font-size: 10px;
}

.hero img {
    animation: 2s hero_img_animation;
    transition: 0.2s opacity, 0.2s transform;
}
@keyframes hero_img_animation {
    from {
        opacity: 0;
        transform: translateY(15%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

article.top-products_slider {
    padding: 50px;
    margin-top: -50px !important;
    position: relative;
}

.sidebar-item * {
    transition: 0.2s all;
}

.sidebar-item_wrapper:hover .sidebar-item,
.sidebar-item.active {
    transition: 0.2s all;
}

.sidebar-item:hover *,
.sidebar-item.active * {
    font-weight: bolder;
    transition: 0.2s all;
}

.sidebar-item_dropdown-panel {
    position: absolute;
    left: 50%;
    right: 0;
    width: 100vw;
    top: 100%;
    transform: translateX(-50%) translateY(0);
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    transition: 0.2s all;
    z-index: 1;
}

.sidebar-item_wrapper:hover .sidebar-item_dropdown-panel {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(0);
}

.sidebar-item_dropdown-panel_category-name {
    opacity: 0.7;
}

.sidebar-item_dropdown-panel img {
    object-position: top left;
    position: absolute;
    right: 0;
    width: 50%;
    z-index: -1;
}

.sidebar-item_dropdown-panel_subcategories {
    position: relative;
}

.mobile-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 998;
}

.mobile-sidebar::before {
    content: '';
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #00000010;
    backdrop-filter: blur(5px);
    position: fixed;
    z-index: -1;
}

.mobile-sidebar .mobile-sidebar_inner {
    top: 50%;
    left: 50%;
    width: 98%;
    transition: 0.2s all;
}

.product-card {
    height: 100%;
}

.rds-card.product-card_standard {
    padding: unset;
}

.rds-card.product-card_standard.product-card_standard__horizontal {
    display: flex;
}

.rds-card.product-card_standard.product-card_standard__horizontal
    .product-card_standard-carousel {
    height: unset;
    width: 40%;
    min-width: 40%;
}

.rds-card.product-card_standard:not(.product-card_standard__horizontal)
    .product_details-link {
    width: 100%;
}

.rds-card.product-card_standard.product-card_standard__horizontal
    .product_details-link {
    width: 60%;
}

.product-card .product-card_name,
.product-card .product-card_description {
    text-overflow: ellipsis;
}

.rds-card.product-card_standard.product-card_standard__horizontal
    .product_variants-tag:not(:empty) {
    padding: unset;
    position: initial;
    margin-top: var(--rds-spacingSmall);
    display: inline-block;
}

.product-card_standard p {
    transition: 0.2s color;
}

.product-card_standard-carousel {
    height: 180px;
    position: relative;
    overflow: hidden;
}

.product-card_standard-carousel_container {
    white-space: nowrap;
    overflow: hidden;
    height: 100%;
}

.product-card_standard-carousel_handler {
    position: absolute;
    cursor: pointer;
    display: block;
    line-height: 2;
    padding: 8px;
    background: #00000090;
    border-radius: 100%;
    width: 45px;
    height: 50px;
    text-align: right;
    color: white;
    transition: 0.2s all;
    left: -22px;
    transform: translateY(-50%) translateX(-100%);
}
.card.product-card_standard:hover .product-card_standard-carousel_handler {
    transform: translateY(-50%) translateX(0);
}
.card.product-card_standard:hover
    .product-card_standard-carousel_handler.disabled {
    opacity: 0.4;
}
.product-card_standard-carousel_handler.right {
    transform: translateY(-50%) translateX(100%);
    left: unset;
    text-align: left;
    right: -22px;
}
.card.product-card_standard:hover
    .product-card_standard-carousel_handler:hover:not(.disabled) {
    transform: translateY(-50%) translateX(0) scale(1.15);
    background: #00000095;
}

.product-card_standard-carousel_handler.disabled {
    pointer-events: none;
}

.product-card_standard-carousel .product-card_description {
    white-space: nowrap;
}

.product_variants-tag {
    position: absolute;
    bottom: calc(100% - var(--rds-spacingSmall));
    z-index: 1;
    width: 100%;
    padding: 0 var(--rds-spacingMedium);
    left: 0;
}

/* PRODUCT FILTERS */
.rds-card.product-filters_panel {
    overflow: visible;
}

@media screen and (min-width: 900px) {
    .rds-flexbox.product-filters_mobile-header {
        display: none !important;
    }
}

@media screen and (max-width: 900px) {
    .product-filters_mobile-ready .rds-card.product-filters_panel,
    .product-filters_mobile-ready.expanded::before {
        z-index: 997;
        width: 100vw;
        position: fixed;
        left: 0;
    }

    .product-filters_mobile-ready .rds-card.product-filters_panel {
        position: fixed;
        bottom: 0;
        transform: translateY(
            calc(100% - 60px)
        ); /* Being 60px the height of the header */
        right: 0;
        box-shadow: 0 0 20px 0 #00000020 !important;
        border-bottom-right-radius: unset !important;
        border-bottom-left-radius: unset !important;
        transition: 0.2s all;
    }

    .product-filters_mobile-ready.expanded .product-filters_panel {
        transform: translateY(0);
    }

    .product-filters_mobile-ready.expanded::before {
        content: '';
        top: 0;
        background-color: #0000006e;
        height: 100vh;
    }
}

/* PRODUCT FILTERS */

.cart-item .cart-item_thumbnail {
    display: block;
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
    object-position: center;
}

.social-networks-share-btns svg {
    filter: grayscale(1) brightness(1.2);
    cursor: pointer;
    transition: 0.2s all;
}

.social-networks-share-btns svg:hover {
    filter: unset;
}

.featured-category {
    background-position: center !important;
    background-size: 110% !important;
    transition: 0.2s all;
}
.featured-category:hover {
    transform: scale(1.04);
    background-size: 105.5% !important;
}
.featured-category header {
    transition: 0.2s all;
    transform-origin: top;
}
.featured-category:hover header {
    transform: scale(0.96);
}

.cart-aside {
    position: fixed;
    height: 100dvh;
    max-width: 100vw;
    width: 420px;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: var(--cartZIndex);
    transition: 0.2s transform;
    transform: translateX(100%);
}

@media screen and (max-width: 900px) {
    .cart-aside {
        width: 100vw;
        max-width: unset;
    }
}

.cart-aside.cart-aside_visible {
    transform: unset;
}
.cart-aside_shadow {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(8px);
    background: rgb(0 0 0 / 50%);
    opacity: 0;
    z-index: -1;
    transition: 0.4s opacity;
    transform: translateX(100%) scaleX(0);
    z-index: calc(var(--cartZIndex) - 1);
}
.cart-aside_shadow_visible {
    opacity: 1;
    transform: translateX(0);
}

.cart-aside .cart {
    height: 100%;
}

.cart-ribbon {
    position: sticky;
    bottom: 0;
    left: 0;
}

.cart-ribbon_complete.rds-btn {
    padding-top: var(--rds-spacingSmall);
    padding-bottom: var(--rds-spacingSmall);
}

@keyframes webstore-builder_highlighted_animation {
    0% {
        box-shadow: 0 0 10px 3px var(--webstoreBuilderHighlightColor);
    }
    50% {
        box-shadow: 0 0 10px 0px var(--webstoreBuilderHighlightColor);
    }
    100% {
        box-shadow: 0 0 10px 3px var(--webstoreBuilderHighlightColor);
    }
}

.webstore-builder_highlighted::after {
    animation: 1.5s webstore-builder_highlighted_animation infinite;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: calc(0px - var(--rds-spacingSmall));
    left: calc(0px - var(--rds-spacingSmall));
    z-index: 1;
    padding: var(--rds-spacingSmall);
    pointer-events: none;
}

.webstore-builder_highlighted::before {
    position: absolute;
    top: calc(0px - (var(--rds-spacingSmall) * 4));
    left: calc(0px - var(--rds-spacingSmall) * -1);
    background-color: var(--webstoreBuilderHighlightColor);
    color: white;
    padding: 5px 10px;
    font-size: 11px;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

/* PRODUCT DETAILS */
.product-picture-selector_picture:not(
        .product-picture-selector_picture-active
    ) {
    opacity: 0.5;
}
/* PRODUCT DETAILS */

.active-purchase-tracker_pictures,
.active-purchase-tracker_details_visible {
    transition: 0.3s all;
}

.active-purchase-tracker_pictures_hidden {
    transform: translateY(10px);
    opacity: 0;
}

.active-purchase-tracker_details {
    visibility: hidden;
    height: 0;
    transform: translateX(50%) translateY(-30%) scale(0.5);
    transform-origin: top right;
    opacity: 0;
    transition: 0.2s all;
}

.active-purchase-tracker_details_visible {
    visibility: visible;
    height: auto;
    transform: unset;
    opacity: 1;
}

.global-search {
    min-width: 250px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.global-search input,
.global-search input:hover,
.global-search input:focus {
    background-color: transparent;
    border: unset;
    outline: unset;
}

.checkout-form_ribbon {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.checkout-form_ribbon_error {
    padding: 5px 20px;
    background-color: var(--rds-colorError) !important;
}

@media screen and (min-width: 450px) {
    .checkout-form .rds-form-select_cards.rds-grid.rds-grid_4-columns {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 900px) {
    .checkout-form_ribbon_inner,
    .checkout-form_ribbon_actions {
        flex-direction: column;
    }

    .checkout-form_ribbon_error.rds-m_right__md {
        text-align: center;
        margin-right: unset;
    }

    .checkout-form_ribbon_inner .rds-btn {
        margin-top: var(--rds-spacingSmall);
        margin-bottom: var(--rds-spacingSmall);
        width: 100%;
    }

    .product-detail_fields .product-detail_field,
    .product-detail_fields .rds-form-item {
        width: 98% !important;
    }
}

.rds-carrousel.horizontal .rds-carrousel-item {
    white-space: unset;
}

@keyframes purchase-details_real-time-indicator_animation {
    0% {
        opacity: 0.4;
        transform: scale(0.8);
    }
    50% {
        transform: scale(1);
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.purchase-details_real-time-indicator {
    animation: 2s purchase-details_real-time-indicator_animation infinite;
}

@keyframes purchase-details_real-time-indicator-halo_animation {
    0% {
        opacity: 0.1;
    }
    50% {
        opacity: 0.5;
    }
    70% {
        opacity: 0.2;
    }
    100% {
        transform: scale(2.5);
        opacity: 0.1;
    }
}

.purchase-details_real-time-indicator::before {
    animation: 2s purchase-details_real-time-indicator-halo_animation infinite;
}
