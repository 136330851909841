.active-purhcase-tracker_product {
    position: relative;
    display: inline-flex;
}

.active-purhcase-tracker_product-qty {
    position: absolute;
    top: -5px;
    right: -5px;
}
