.nav_menu {
    cursor: pointer;
}

/* Desktop */
@media screen and (min-width: 900px) {
    .nav_menu {
        display: none;
    }
}

/* Mobile */
@media screen and (max-width: 900px) {
    .sidebar-items,
    .nav_user-info {
        display: none;
    }

    aside .topbar_login-button {
        display: flex;
    }
}
