* {
    --productDetailPictureSelectorPictureWidth: 80px;
}

.product-detail_pictures-section {
    grid-template-columns: var(--productDetailPictureSelectorPictureWidth) auto;
}

.product-detail_pictures-section_individual {
    grid-template-columns: auto;
}

.product-picture-selector {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
}

.product-picture-selector_picture {
    display: inline-block;
    width: 100%;
    height: var(--productDetailPictureSelectorPictureWidth);
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}

.product-detail_current-picture {
    width: 100%;
}

@media screen and (max-width: 900px) {
    .product-detail_inner,
    .product-detail_pictures-section {
        grid-template-columns: 100% !important;
    }

    .product-detail_current-picture {
        width: 100%;
        margin: 0 0 var(--rds-spacingSmall) 0;
        order: -1;
    }

    .product-picture-selector {
        flex-direction: initial !important;
    }

    .product-picture-selector_picture {
        height: var(--productDetailPictureSelectorPictureWidth);
        width: var(--productDetailPictureSelectorPictureWidth);
    }

    .product-detail_inner button {
        width: 100%;
    }

    .product-detail_fields {
        display: grid;
    }
}
